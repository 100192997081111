* {
    border-collapse: collapse;
}

@media (max-width: 750px) {
    * {
        font-size: 0.75rem;
    }
}

.Overview {
    width: 90%;
    height: 90%;
    color: whitesmoke;
    border-collapse: collapse;
    margin: auto;
}

.title {
    color: royalblue;
    font-size: 2em;
    text-align: right;
    padding: 0;
    margin: 0;
    font-family: 'Rubik Glitch', cursive;
}

.toggleInactive {
    cursor: pointer;
    font-size: 0.8em;
    color: #E7DFDD;
    text-align: right;
    font-family: 'Montserrat Alternates', sans-serif;
    font-weight: 400;
}

.gridContainer {
    display: grid;
    grid-template-columns: 15em 1fr;
    width: 100%;
}

.gridItem,.gridItemWide {
    margin: 2px;
    padding: 10px;
    color: #E7DFDD;
    cursor: pointer;
}

.gridItemWide{
    grid-column: span 2;
}

.gridItem:nth-child(odd) {
    text-align: right;
}

.gridItem:nth-child(even) {
    text-align: left;
}

.driver {
    background-color: #4714F6;
}

.driverName {
    font-weight: 800;
    font-size: 2em;
    font-family: 'Montserrat Alternates', sans-serif;
}

.driverName:nth-child(1) {
    font-size: 1.2em;
}

.driverName:nth-child(2) {
    font-size: 2em;
}

.points {
    font-weight: 700;
    border: #4714F6 1px solid;
    width: 100%;
    overflow: hidden;
    display: inline-block;
    font-family: 'Montserrat Alternates', sans-serif;
}

.pointsPoints {
    font-size: 2em;
}

.pointsText {
    font-size: 1em;
}

.empty {

}

.showDriver {
    margin: 2px;
    padding: 10px;
    color: #E7DFDD;
    cursor: pointer;
    width: 100%;
    border: #4714F6 1px dotted;
    font-family: 'Montserrat Alternates', sans-serif;
    font-weight: 400;
    font-size: 0.9em;
}

.penaltyTable {
    border-collapse: separate;
    border-spacing: 4px;
}

.admin {
    color: #e7dfdd;
    padding: 10px;
}